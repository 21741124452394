import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import {} from "@";
import store from "./store";
import installElementPlus from "./plugins/element";
import "./assets/style/v1.0.scss";
import "@/permission"; // permission control
import { drag } from "./directive/drag.ts";
const app = createApp(App);
installElementPlus(app);
//临时修改
// store
//   .dispatch("user/login", {
//     code: "测试申报单位",
//     password: "123456",
//     logOnType: 0,
//     logOnHuman: null,
//   })
//   .then(() => {
//     app.directive("drag", drag);
//     app.use(store).use(router).mount("#app");
//   });


app.directive("drag", drag);
app.use(store).use(router).mount("#app");
export default app;
